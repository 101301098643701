/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import {
  CarrierCarPostBody,
  CarrierCarPostBodyFromJSON,
  CarrierCarPostBodyToJSON,
  CarrierCarPutBody,
  CarrierCarPutBodyFromJSON,
  CarrierCarPutBodyToJSON,
  CarrierLoadPostBody,
  CarrierLoadPostBodyFromJSON,
  CarrierLoadPostBodyToJSON,
  CarrierLoadPutBody,
  CarrierLoadPutBodyFromJSON,
  CarrierLoadPutBodyToJSON,
  ErrorResponse,
  ErrorResponseFromJSON,
  ErrorResponseToJSON,
  InlineResponse200,
  InlineResponse200FromJSON,
  InlineResponse200ToJSON,
  InlineResponse2001,
  InlineResponse2001FromJSON,
  InlineResponse2001ToJSON,
  InlineResponse201,
  InlineResponse201FromJSON,
  InlineResponse201ToJSON,
  InlineResponse2011,
  InlineResponse2011FromJSON,
  InlineResponse2011ToJSON,
} from '../models';

export interface CarriersApiDeleteCarrierCarRequest {
  carrierId: number;
  carId: number;
}

export interface CarriersApiGetCarrierCarRequest {
  carrierId: number;
  carId: number;
}

export interface CarriersApiGetCarrierCarByLicencePlateRequest {
  carrierId: number;
  carLicencePlate: string;
}

export interface CarriersApiGetCarrierCarsRequest {
  carrierId: number;
}

export interface CarriersApiGetCarrierLoadRequest {
  carrierId: number;
  loadId: number;
}

export interface CarriersApiGetCarrierLoadsRequest {
  carrierId: number;
  next?: number;
  driveDateRangeStartsAt?: Date;
  driveDateRangeEndsAt?: Date;
}

export interface CarriersApiPostCarrierCarRequest {
  carrierId: number;
  carrierCarPostBody: CarrierCarPostBody;
}

export interface CarriersApiPostCarrierLoadRequest {
  carrierId: number;
  carrierLoadPostBody: CarrierLoadPostBody;
}

export interface CarriersApiPutCarrierCarRequest {
  carrierId: number;
  carId: number;
  carrierCarPutBody: CarrierCarPutBody;
}

export interface CarriersApiPutCarrierLoadRequest {
  carrierId: number;
  loadId: number;
  carrierLoadPutBody: CarrierLoadPutBody;
}

/**
 *
 */
export class CarriersApi extends runtime.BaseAPI {
  /**
   */
  async deleteCarrierCarRaw(
    requestParameters: CarriersApiDeleteCarrierCarRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.carrierId === null || requestParameters.carrierId === undefined) {
      throw new runtime.RequiredError(
        'carrierId',
        'Required parameter requestParameters.carrierId was null or undefined when calling deleteCarrierCar.',
      );
    }

    if (requestParameters.carId === null || requestParameters.carId === undefined) {
      throw new runtime.RequiredError(
        'carId',
        'Required parameter requestParameters.carId was null or undefined when calling deleteCarrierCar.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v2/carriers/{carrier_id}/cars/{car_id}`
          .replace(`{${'carrier_id'}}`, encodeURIComponent(String(requestParameters.carrierId)))
          .replace(`{${'car_id'}}`, encodeURIComponent(String(requestParameters.carId))),
        method: 'DELETE',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   */
  async deleteCarrierCar(
    requestParameters: CarriersApiDeleteCarrierCarRequest,
    initOverrides?: RequestInit,
  ): Promise<void> {
    await this.deleteCarrierCarRaw(requestParameters, initOverrides);
  }

  /**
   */
  async getCarrierCarRaw(
    requestParameters: CarriersApiGetCarrierCarRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<InlineResponse201>> {
    if (requestParameters.carrierId === null || requestParameters.carrierId === undefined) {
      throw new runtime.RequiredError(
        'carrierId',
        'Required parameter requestParameters.carrierId was null or undefined when calling getCarrierCar.',
      );
    }

    if (requestParameters.carId === null || requestParameters.carId === undefined) {
      throw new runtime.RequiredError(
        'carId',
        'Required parameter requestParameters.carId was null or undefined when calling getCarrierCar.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v2/carriers/{carrier_id}/cars/{car_id}`
          .replace(`{${'carrier_id'}}`, encodeURIComponent(String(requestParameters.carrierId)))
          .replace(`{${'car_id'}}`, encodeURIComponent(String(requestParameters.carId))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse201FromJSON(jsonValue));
  }

  /**
   */
  async getCarrierCar(
    requestParameters: CarriersApiGetCarrierCarRequest,
    initOverrides?: RequestInit,
  ): Promise<InlineResponse201> {
    const response = await this.getCarrierCarRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async getCarrierCarByLicencePlateRaw(
    requestParameters: CarriersApiGetCarrierCarByLicencePlateRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<InlineResponse201>> {
    if (requestParameters.carrierId === null || requestParameters.carrierId === undefined) {
      throw new runtime.RequiredError(
        'carrierId',
        'Required parameter requestParameters.carrierId was null or undefined when calling getCarrierCarByLicencePlate.',
      );
    }

    if (requestParameters.carLicencePlate === null || requestParameters.carLicencePlate === undefined) {
      throw new runtime.RequiredError(
        'carLicencePlate',
        'Required parameter requestParameters.carLicencePlate was null or undefined when calling getCarrierCarByLicencePlate.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v2/carriers/{carrier_id}/cars/by_licence_plate/{car_licence_plate}`
          .replace(`{${'carrier_id'}}`, encodeURIComponent(String(requestParameters.carrierId)))
          .replace(`{${'car_licence_plate'}}`, encodeURIComponent(String(requestParameters.carLicencePlate))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse201FromJSON(jsonValue));
  }

  /**
   */
  async getCarrierCarByLicencePlate(
    requestParameters: CarriersApiGetCarrierCarByLicencePlateRequest,
    initOverrides?: RequestInit,
  ): Promise<InlineResponse201> {
    const response = await this.getCarrierCarByLicencePlateRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async getCarrierCarsRaw(
    requestParameters: CarriersApiGetCarrierCarsRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<InlineResponse200>> {
    if (requestParameters.carrierId === null || requestParameters.carrierId === undefined) {
      throw new runtime.RequiredError(
        'carrierId',
        'Required parameter requestParameters.carrierId was null or undefined when calling getCarrierCars.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v2/carriers/{carrier_id}/cars`.replace(
          `{${'carrier_id'}}`,
          encodeURIComponent(String(requestParameters.carrierId)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse200FromJSON(jsonValue));
  }

  /**
   */
  async getCarrierCars(
    requestParameters: CarriersApiGetCarrierCarsRequest,
    initOverrides?: RequestInit,
  ): Promise<InlineResponse200> {
    const response = await this.getCarrierCarsRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async getCarrierLoadRaw(
    requestParameters: CarriersApiGetCarrierLoadRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<InlineResponse2011>> {
    if (requestParameters.carrierId === null || requestParameters.carrierId === undefined) {
      throw new runtime.RequiredError(
        'carrierId',
        'Required parameter requestParameters.carrierId was null or undefined when calling getCarrierLoad.',
      );
    }

    if (requestParameters.loadId === null || requestParameters.loadId === undefined) {
      throw new runtime.RequiredError(
        'loadId',
        'Required parameter requestParameters.loadId was null or undefined when calling getCarrierLoad.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v2/carriers/{carrier_id}/loads/{load_id}`
          .replace(`{${'carrier_id'}}`, encodeURIComponent(String(requestParameters.carrierId)))
          .replace(`{${'load_id'}}`, encodeURIComponent(String(requestParameters.loadId))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse2011FromJSON(jsonValue));
  }

  /**
   */
  async getCarrierLoad(
    requestParameters: CarriersApiGetCarrierLoadRequest,
    initOverrides?: RequestInit,
  ): Promise<InlineResponse2011> {
    const response = await this.getCarrierLoadRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async getCarrierLoadsRaw(
    requestParameters: CarriersApiGetCarrierLoadsRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<InlineResponse2001>> {
    if (requestParameters.carrierId === null || requestParameters.carrierId === undefined) {
      throw new runtime.RequiredError(
        'carrierId',
        'Required parameter requestParameters.carrierId was null or undefined when calling getCarrierLoads.',
      );
    }

    const queryParameters: any = {};

    if (requestParameters.next !== undefined) {
      queryParameters['next'] = requestParameters.next;
    }

    if (requestParameters.driveDateRangeStartsAt !== undefined) {
      queryParameters['drive_date_range_starts_at'] = (requestParameters.driveDateRangeStartsAt as any)
        .toISOString()
        .substr(0, 10);
    }

    if (requestParameters.driveDateRangeEndsAt !== undefined) {
      queryParameters['drive_date_range_ends_at'] = (requestParameters.driveDateRangeEndsAt as any)
        .toISOString()
        .substr(0, 10);
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v2/carriers/{carrier_id}/loads`.replace(
          `{${'carrier_id'}}`,
          encodeURIComponent(String(requestParameters.carrierId)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse2001FromJSON(jsonValue));
  }

  /**
   */
  async getCarrierLoads(
    requestParameters: CarriersApiGetCarrierLoadsRequest,
    initOverrides?: RequestInit,
  ): Promise<InlineResponse2001> {
    const response = await this.getCarrierLoadsRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async postCarrierCarRaw(
    requestParameters: CarriersApiPostCarrierCarRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<InlineResponse201>> {
    if (requestParameters.carrierId === null || requestParameters.carrierId === undefined) {
      throw new runtime.RequiredError(
        'carrierId',
        'Required parameter requestParameters.carrierId was null or undefined when calling postCarrierCar.',
      );
    }

    if (requestParameters.carrierCarPostBody === null || requestParameters.carrierCarPostBody === undefined) {
      throw new runtime.RequiredError(
        'carrierCarPostBody',
        'Required parameter requestParameters.carrierCarPostBody was null or undefined when calling postCarrierCar.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/api/v2/carriers/{carrier_id}/cars`.replace(
          `{${'carrier_id'}}`,
          encodeURIComponent(String(requestParameters.carrierId)),
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: CarrierCarPostBodyToJSON(requestParameters.carrierCarPostBody),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse201FromJSON(jsonValue));
  }

  /**
   */
  async postCarrierCar(
    requestParameters: CarriersApiPostCarrierCarRequest,
    initOverrides?: RequestInit,
  ): Promise<InlineResponse201> {
    const response = await this.postCarrierCarRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async postCarrierLoadRaw(
    requestParameters: CarriersApiPostCarrierLoadRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<InlineResponse2011>> {
    if (requestParameters.carrierId === null || requestParameters.carrierId === undefined) {
      throw new runtime.RequiredError(
        'carrierId',
        'Required parameter requestParameters.carrierId was null or undefined when calling postCarrierLoad.',
      );
    }

    if (requestParameters.carrierLoadPostBody === null || requestParameters.carrierLoadPostBody === undefined) {
      throw new runtime.RequiredError(
        'carrierLoadPostBody',
        'Required parameter requestParameters.carrierLoadPostBody was null or undefined when calling postCarrierLoad.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/api/v2/carriers/{carrier_id}/loads`.replace(
          `{${'carrier_id'}}`,
          encodeURIComponent(String(requestParameters.carrierId)),
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: CarrierLoadPostBodyToJSON(requestParameters.carrierLoadPostBody),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse2011FromJSON(jsonValue));
  }

  /**
   */
  async postCarrierLoad(
    requestParameters: CarriersApiPostCarrierLoadRequest,
    initOverrides?: RequestInit,
  ): Promise<InlineResponse2011> {
    const response = await this.postCarrierLoadRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async putCarrierCarRaw(
    requestParameters: CarriersApiPutCarrierCarRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<InlineResponse201>> {
    if (requestParameters.carrierId === null || requestParameters.carrierId === undefined) {
      throw new runtime.RequiredError(
        'carrierId',
        'Required parameter requestParameters.carrierId was null or undefined when calling putCarrierCar.',
      );
    }

    if (requestParameters.carId === null || requestParameters.carId === undefined) {
      throw new runtime.RequiredError(
        'carId',
        'Required parameter requestParameters.carId was null or undefined when calling putCarrierCar.',
      );
    }

    if (requestParameters.carrierCarPutBody === null || requestParameters.carrierCarPutBody === undefined) {
      throw new runtime.RequiredError(
        'carrierCarPutBody',
        'Required parameter requestParameters.carrierCarPutBody was null or undefined when calling putCarrierCar.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/api/v2/carriers/{carrier_id}/cars/{car_id}`
          .replace(`{${'carrier_id'}}`, encodeURIComponent(String(requestParameters.carrierId)))
          .replace(`{${'car_id'}}`, encodeURIComponent(String(requestParameters.carId))),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: CarrierCarPutBodyToJSON(requestParameters.carrierCarPutBody),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse201FromJSON(jsonValue));
  }

  /**
   */
  async putCarrierCar(
    requestParameters: CarriersApiPutCarrierCarRequest,
    initOverrides?: RequestInit,
  ): Promise<InlineResponse201> {
    const response = await this.putCarrierCarRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async putCarrierLoadRaw(
    requestParameters: CarriersApiPutCarrierLoadRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<InlineResponse2011>> {
    if (requestParameters.carrierId === null || requestParameters.carrierId === undefined) {
      throw new runtime.RequiredError(
        'carrierId',
        'Required parameter requestParameters.carrierId was null or undefined when calling putCarrierLoad.',
      );
    }

    if (requestParameters.loadId === null || requestParameters.loadId === undefined) {
      throw new runtime.RequiredError(
        'loadId',
        'Required parameter requestParameters.loadId was null or undefined when calling putCarrierLoad.',
      );
    }

    if (requestParameters.carrierLoadPutBody === null || requestParameters.carrierLoadPutBody === undefined) {
      throw new runtime.RequiredError(
        'carrierLoadPutBody',
        'Required parameter requestParameters.carrierLoadPutBody was null or undefined when calling putCarrierLoad.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/api/v2/carriers/{carrier_id}/loads/{load_id}`
          .replace(`{${'carrier_id'}}`, encodeURIComponent(String(requestParameters.carrierId)))
          .replace(`{${'load_id'}}`, encodeURIComponent(String(requestParameters.loadId))),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: CarrierLoadPutBodyToJSON(requestParameters.carrierLoadPutBody),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse2011FromJSON(jsonValue));
  }

  /**
   */
  async putCarrierLoad(
    requestParameters: CarriersApiPutCarrierLoadRequest,
    initOverrides?: RequestInit,
  ): Promise<InlineResponse2011> {
    const response = await this.putCarrierLoadRaw(requestParameters, initOverrides);
    return await response.value();
  }
}
