import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useNavigationType } from 'react-router-dom';
import {
  api,
  getAllPages,
  Organization,
  SearchOrganizationShipmentsPostBody,
  SearchShipmentsPostBody,
  Shipment,
  ShipmentStateEnum,
} from '../../api';
import Notification, { NotificationType, SnackbarPropsWithSeverity } from '../../components/Notification';
import { Button } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { Loading } from '../../components/Loading';
import { DateTime } from 'luxon';
import {
  FormInput,
  FormKeyboardDatePicker,
  FormLast30DaysCheckbox,
  FormSelect,
} from './components/searchShipmentsInputs';
import { saveShipmentSet } from './components/ShipmentSetHistory';
import {
  StyledFormContainer,
  StyledFormContentContainer,
  StyledFormContentContainerMiddle,
  StyledFormFieldSet,
} from '../../components/StyledComponents/StyledFormComponents';
import { useCurrentUser } from '../../hooks/useCurrentUser';
import { canAccessCoordination } from '../../utils';
import { MRT_TableOptions } from 'material-react-table';
import { getShipmentColumns } from './components/shipmentColumns';
import MRT from '../../components/MaterialReactTable/MRT';
import { ShipmentRowSubRowAsync } from '../../components/MaterialReactTable/MRTShipmentRowSubRow';
import Main from '../../components/Main';
import { ShipmentOrOrganizationShipmentWithOrganizationName } from './shipment.state';

const VIEW_ID = 'search_shipments' as const;

export interface Fields {
  delivery_name: string;
  delivery_address: string;
  delivery_phone_number: string;
  pickup_name: string;
  pickup_address: string;
  reference_number: string;
  id: string;
  job_number: string;
  delivery_date: DateTime | null;
  last30DaysAndOnwards: boolean;
  state: string;
}

interface EnrichedShipment extends Shipment {
  organizationName?: string;
}

interface SearchShipmentsMemory {
  delivery_name: string;
  delivery_address: string;
  delivery_phone_number: string;
  pickup_name: string;
  pickup_address: string;
  reference_number: string;
  id: string;
  job_number: string;
  delivery_date: string;
  last30DaysAndOnwards: boolean;
  state: string;
}

const SearchShipments: FunctionComponent = () => {
  const navigationType = useNavigationType();
  const memory: SearchShipmentsMemory =
    navigationType === 'POP' ? JSON.parse(sessionStorage.getItem('search_shipments_navigate') || '{}') : '';
  const formMethods = useForm<Fields>({
    defaultValues: {
      delivery_name: memory.delivery_name ?? '',
      delivery_address: memory.delivery_address ?? '',
      delivery_phone_number: memory.delivery_phone_number ?? '',
      pickup_name: memory.pickup_name ?? '',
      pickup_address: memory.pickup_address ?? '',
      reference_number: memory.reference_number ?? '',
      id: memory.id ?? '',
      job_number: memory.job_number ?? '',
      delivery_date: memory.delivery_date ? DateTime.fromISO(memory.delivery_date) : null,
      last30DaysAndOnwards: memory.last30DaysAndOnwards ?? true,
      state: memory.state ?? '',
    },
  });
  const { handleSubmit } = formMethods;
  const [isLoading, setIsLoading] = useState(true);
  const [notification, setNotification] = useState<NotificationType>({ message: null });
  const snackbarProps: SnackbarPropsWithSeverity = {
    onClose: (): void => setNotification({ message: null, severity: 'success' }),
    open: notification.message !== null,
    message: notification.message,
    key: notification.message,
    severity: notification.severity,
  };
  const [shipments, setShipments] = useState<EnrichedShipment[]>([]);
  const [shipmentSetId, setShipmentSetId] = useState<string>('');
  const [organizations, setOrganizations] = useState<Organization[]>([]);
  const currentUser = useCurrentUser();
  const hasCoordinationAccess = canAccessCoordination(currentUser);
  const [error, setError] = React.useState(false);

  const load = async (abortController?: AbortController) => {
    try {
      setIsLoading(true);
      if (hasCoordinationAccess) {
        setOrganizations((await api.organizations.getOrganizations({})).data);
      }
      if (
        navigationType === 'POP' &&
        (memory.delivery_name ||
          memory.delivery_address ||
          memory.delivery_phone_number ||
          memory.delivery_date ||
          memory.id ||
          memory.job_number ||
          memory.pickup_name ||
          memory.pickup_address ||
          memory.reference_number ||
          memory.state)
      ) {
        searchShipments(formMethods.getValues(), abortController);
      } else {
        sessionStorage.clear();
      }
    } catch (err) {
      console.error(err);
      setNotification({ message: 'Lataus epäonnistui', severity: 'error' });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (currentUser) {
      const abortController = new AbortController();
      load(abortController);
      return () => abortController.abort();
    }
  }, [currentUser]);

  const getSearchShipmentsPostBody = (fields: Fields): SearchShipmentsPostBody => {
    return {
      agreed_delivery_window_date_range_starts_at: fields.delivery_date
        ? fields.delivery_date.startOf('day').toJSDate()
        : fields.last30DaysAndOnwards
          ? DateTime.local().startOf('day').minus({ days: 30 }).toJSDate()
          : undefined,
      agreed_delivery_window_date_range_ends_at: fields.delivery_date
        ? fields.delivery_date.endOf('day').toJSDate()
        : undefined,
      delivery_name: fields.delivery_name || undefined,
      delivery_address: fields.delivery_address || undefined,
      delivery_phone_number: fields.delivery_phone_number || undefined,
      pickup_name: fields.pickup_name || undefined,
      pickup_address: fields.pickup_address || undefined,
      job_number: fields.job_number || undefined,
      reference_number: fields.reference_number || undefined,
      id: fields.id ? parseInt(fields.id) : undefined,
      state: (fields.state as ShipmentStateEnum) || undefined,
    };
  };

  const setSearchShipmentsnavigate = (fields: Fields) => {
    sessionStorage.setItem(
      'search_shipments_navigate',
      JSON.stringify({
        delivery_name: fields.delivery_name,
        delivery_address: fields.delivery_address,
        delivery_phone_number: fields.delivery_phone_number,
        pickup_name: fields.pickup_name,
        pickup_address: fields.pickup_address,
        reference_number: fields.reference_number,
        id: fields.id,
        job_number: fields.job_number,
        delivery_date: fields.delivery_date,
        last30DaysAndOnwards: fields.last30DaysAndOnwards,
        state: fields.state,
      }),
    );
  };

  const searchShipmentsHandler = async (fields: Fields) => searchShipments(fields, undefined);

  const searchShipments = async (fields: Fields, abortController: AbortController | undefined) => {
    try {
      setIsLoading(true);
      if (!currentUser) {
        return;
      }
      const shipments = hasCoordinationAccess
        ? await getAllPages(
            api.search.searchShipments.bind(api.search),
            {
              searchShipmentsPostBody: getSearchShipmentsPostBody(fields),
            },
            abortController,
          )
        : await getAllPages(
            api.search.searchOrganizationShipments.bind(api.search),
            {
              searchOrganizationShipmentsPostBody: getSearchShipmentsPostBody(
                fields,
              ) as SearchOrganizationShipmentsPostBody,
              organizationId: currentUser.organization_id,
            },
            abortController,
          );
      const shipmentsWithOrganizatonName = shipments.map((shipment) => {
        const organization = organizations.find((org) => org.id === shipment.organization_id);
        return {
          ...shipment,
          organizationName: organization?.name,
        };
      });
      setShipments(shipmentsWithOrganizatonName);
      setShipmentSetId(saveShipmentSet(shipmentsWithOrganizatonName));
      setSearchShipmentsnavigate(fields);
    } catch (err) {
      console.error(err);
      setNotification({ message: 'Virhe haettaessa toimituksia', severity: 'error' });
    } finally {
      setIsLoading(false);
    }
  };

  const data = React.useMemo(() => shipments, [shipments]);

  const shipmentColumns = getShipmentColumns({ shipmentSetId: shipmentSetId, loadLink: hasCoordinationAccess });

  const columns = useMemo(
    () =>
      shipmentColumns.filter(
        (column) =>
          hasCoordinationAccess || !['organizationName', 'delivery_phone_number'].includes(column.accessorKey ?? ''),
      ),
    [shipmentSetId, currentUser],
  );

  const tableOptions: MRT_TableOptions<ShipmentOrOrganizationShipmentWithOrganizationName> = {
    data,
    columns,
    renderDetailPanel: ({ row }) => <ShipmentRowSubRowAsync row={row} />,
  };

  if (!currentUser) {
    return null;
  }

  return (
    <Main>
      <Loading isLoading={isLoading} />
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(searchShipmentsHandler)} autoComplete="off">
          <StyledFormFieldSet>
            <legend>Etsi toimituksia</legend>
            <StyledFormContainer>
              <StyledFormContentContainer>
                <FormInput type="number" disabled={isLoading} label="Toimitusnumero" name="id" />
                <FormInput disabled={isLoading} label="Asiakasviite" name="reference_number" />
                <FormInput disabled={isLoading} label="Työnumero" name="job_number" />
                <FormSelect disabled={isLoading} label="Tila" name="state" />
              </StyledFormContentContainer>
              <StyledFormContentContainerMiddle>
                <FormInput disabled={isLoading} label="Noutonimi" name="pickup_name" />
                <FormInput disabled={isLoading} label="Nouto-osoite" name="pickup_address" />
              </StyledFormContentContainerMiddle>
              <StyledFormContentContainer>
                <FormInput disabled={isLoading} label="Toimitusnimi" name="delivery_name" />
                <FormInput disabled={isLoading} label="Toimitusosoite" name="delivery_address" />
                <FormInput disabled={isLoading} label="Puhelinnumero" name="delivery_phone_number" />
                <FormKeyboardDatePicker
                  disabled={isLoading}
                  setError={setError}
                  label="Toimituspäivä"
                  name="delivery_date"
                />
              </StyledFormContentContainer>
            </StyledFormContainer>
            <StyledFormContentContainer>
              <FormLast30DaysCheckbox
                disabled={isLoading}
                label="Näytä hakutulokset vain 30 päivää vanhoista toimituksista eteenpäin"
                name="last30DaysAndOnwards"
                formMethods={formMethods}
              />
              <Button type="submit" data-cy="search-button" disabled={isLoading || error}>
                Etsi
              </Button>
            </StyledFormContentContainer>
          </StyledFormFieldSet>
        </form>
      </FormProvider>
      <MRT header={'Toimitukset'} viewId={VIEW_ID} isLoading={isLoading} {...tableOptions} />
      <Notification {...snackbarProps} />
    </Main>
  );
};

export default SearchShipments;
