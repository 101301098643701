import { cloneDeep, orderBy } from 'lodash';
import { DateTime } from 'luxon';
import React from 'react';
import { Shipment, ShipmentRow, ShipmentRowStateEnum, ShipmentStateEnum, User } from './api';
import theme from './theme';
import { formatShipmentRowState, formatShortDateTime } from './formatters';
import { AvTimer, Check, Circle, Clear, DoNotDisturb, LocalShipping } from '@mui/icons-material';
import { Chip } from '@mui/material';

export const isEven = (n: number) => n & 1;

export const roundToTwoDecimals = (n: number): number => Math.round((n + Number.EPSILON) * 100) / 100;

export const orderShipments = <T extends Shipment>(shipments: T[]): T[] => {
  const orderedShipments = orderBy(cloneDeep(shipments), 'order_in_load');
  orderedShipments.forEach((shipment, index) => {
    shipment.order_in_load = index + 1;
  });
  return orderedShipments;
};

export const reverseShipments = <T extends Shipment>(shipments: T[]): T[] => {
  const orderedShipments = orderBy(cloneDeep(shipments), 'order_in_load');
  orderedShipments.forEach((shipment, index) => {
    shipment.order_in_load = shipments.length - index;
  });
  return orderedShipments;
};

export const getShipmentsOfLoadAfterKeyPress = <T extends Shipment>(
  shipmentsOfLoad: T[],
  shipment: T,
  keyEvent: React.KeyboardEvent,
): T[] | undefined => {
  if (!shipment.load_id) {
    return shipmentsOfLoad; // This case should not happen as it's checked already earlier
  }
  shipmentsOfLoad = orderShipments(cloneDeep(shipmentsOfLoad));
  if (keyEvent.key === 'ArrowUp') {
    const shipmentOfLoad = shipmentsOfLoad.find((s) => s.id === shipment.id);
    if (shipmentOfLoad === undefined) {
      return;
    }
    const newOrderInLoad = (shipmentOfLoad.order_in_load as number) - 1;
    if (newOrderInLoad <= 0) {
      return;
    }
    const shipmentWithSameOrderInLoad = shipmentsOfLoad.find((shipment) => newOrderInLoad === shipment.order_in_load);
    if (shipmentWithSameOrderInLoad) {
      shipmentWithSameOrderInLoad.order_in_load = newOrderInLoad + 1;
    }
    shipmentOfLoad.order_in_load = newOrderInLoad;
  }
  if (keyEvent.key === 'ArrowDown') {
    const shipmentOfLoad = shipmentsOfLoad.find((s) => s.id === shipment.id);
    if (shipmentOfLoad === undefined) {
      return;
    }
    const newOrderInLoad = (shipmentOfLoad.order_in_load as number) + 1;
    if (newOrderInLoad >= shipmentsOfLoad.length + 1) {
      return;
    }
    const shipmentWithSameOrderInLoad = shipmentsOfLoad.find((shipment) => newOrderInLoad === shipment.order_in_load);
    if (shipmentWithSameOrderInLoad) {
      shipmentWithSameOrderInLoad.order_in_load = newOrderInLoad - 1;
    }
    shipmentOfLoad.order_in_load = newOrderInLoad;
  }
  shipmentsOfLoad = orderShipments(shipmentsOfLoad);
  return shipmentsOfLoad;
};

export const ifDateExistGetSameDateAtMidday = (date: Date | string | null) => {
  if (date === null) {
    return null;
  }
  if (typeof date === 'string') {
    const newDate = DateTime.fromISO(date, { zone: 'utc' }).toJSDate();
    newDate.setHours(12, 0, 0, 0);
    return newDate;
  }
  if (date instanceof Date) {
    const newDate = DateTime.fromJSDate(date, { zone: 'utc' }).toJSDate();
    newDate.setHours(12, 0, 0, 0);
    return newDate;
  }
};
//const shipmentsOfLoad = getShipmentsOfLoadAfterKeyPress(state.shipmentsByLoad[shipment.load_id], shipment, e);

export const isTouchDevive = () => {
  return navigator.maxTouchPoints || 'ontouchstart' in document.documentElement;
};

export const isPwa = () => {
  // Display mode must match the manifest display mode
  return window.matchMedia('(display-mode: standalone)').matches;
};

export const isDriverOnly = (user: User | undefined): boolean => {
  if (user === undefined) {
    return false;
  }
  return (
    user.is_driver === true &&
    !(
      user.is_admin === true ||
      user.is_coordinator === true ||
      user.is_manager === true ||
      user.is_workshop === true ||
      user.is_superuser === true
    )
  );
};

export const isWeekend = (dateTime: DateTime) => dateTime.weekday === 6 || dateTime.weekday === 7;

export const isToday = (date: Date | DateTime): boolean => {
  const dateTime = DateTime.isDateTime(date) ? date : DateTime.fromJSDate(date);
  const today = DateTime.now();
  return dateTime.startOf('day').equals(today.startOf('day'));
};

export const isPwaOrDriverOnly = (user: User | undefined): boolean => isDriverOnly(user) || isPwa();

export const getStateAndTimeFromShipment = (shipment: Shipment, sort?: boolean) => {
  const getStateTimeStamp = (): number | string => {
    switch (shipment.state) {
      case ShipmentStateEnum.Toimitettu:
        if (shipment.delivered_at) {
          return sort ? shipment.delivered_at.getTime() : formatShortDateTime(shipment.delivered_at);
        }
      case ShipmentStateEnum.Toimituskohteessa:
        if (shipment.arrived_to_delivery_location_at) {
          return sort
            ? shipment.arrived_to_delivery_location_at.getTime()
            : formatShortDateTime(shipment.arrived_to_delivery_location_at);
        }
      case ShipmentStateEnum.Noudettu:
        if (shipment.picked_up_at) {
          return sort ? shipment.picked_up_at.getTime() : formatShortDateTime(shipment.picked_up_at);
        }
      case ShipmentStateEnum.Noutokohteessa:
        if (shipment.arrived_to_pickup_location_at) {
          return sort
            ? shipment.arrived_to_pickup_location_at.getTime()
            : formatShortDateTime(shipment.arrived_to_pickup_location_at);
        }
      default:
        return '';
    }
  };

  return `${shipment.state} ${getStateTimeStamp()}`;
};

export const getStateAndTimeStampFromShipment = (shipment: Shipment) => {
  const getStateTimeStamp = (): Date | undefined | null => {
    switch (shipment.state) {
      case ShipmentStateEnum.Toimitettu:
        return shipment.delivered_at;
      case ShipmentStateEnum.Toimituskohteessa:
        return shipment.arrived_to_delivery_location_at;
      case ShipmentStateEnum.Noudettu:
        return shipment.picked_up_at;
      case ShipmentStateEnum.Noutokohteessa:
        return shipment.arrived_to_pickup_location_at;
      default:
        return undefined;
    }
  };

  const timeStampRaw = getStateTimeStamp();
  const timeStamp = timeStampRaw ? timeStampRaw.getTime() : undefined;
  const formattedText = timeStampRaw ? formatShortDateTime(timeStampRaw) : '';

  return { state: shipment.state, timeStamp, formattedText };
};

export const sortShipmentByStateAndTimestamp = (shipment1: Shipment, shipment2: Shipment) => {
  const rowAStateAndTime = getStateAndTimeStampFromShipment(shipment1);
  const rowBStateAndTime = getStateAndTimeStampFromShipment(shipment2);
  const minJSDateInMilliseconds = -8640000000000000;
  const order = [
    ShipmentStateEnum.Peruttu,
    ShipmentStateEnum.Odottaa,
    ShipmentStateEnum.EiVarastossa,
    ShipmentStateEnum.Noutokohteessa,
    ShipmentStateEnum.Noudettavissa,
    ShipmentStateEnum.Noudettu,
    ShipmentStateEnum.Toimituskohteessa,
    ShipmentStateEnum.Toimitettu,
  ];
  return (
    order.indexOf(shipment1.state) - order.indexOf(shipment2.state) ||
    (rowAStateAndTime.timeStamp ?? minJSDateInMilliseconds) - (rowBStateAndTime.timeStamp ?? minJSDateInMilliseconds)
  );
};

type ShipmentStateUtil = {
  icon: JSX.Element;
  backgroundColor: string;
  color: string;
};

export const getShipmentStateUtils = (state: ShipmentStateEnum): ShipmentStateUtil => {
  const styles = { sx: { fontSize: '0.9rem' } };
  switch (state) {
    case ShipmentStateEnum.Noudettavissa: {
      return {
        icon: <Circle {...styles} />,
        backgroundColor: theme.palette.readyForPickup,
        color: theme.palette.common.black,
      };
    }
    case ShipmentStateEnum.Noudettu: {
      return {
        icon: <LocalShipping {...styles} />,
        backgroundColor: theme.palette.pickedUp,
        color: theme.palette.common.black,
      };
    }
    case ShipmentStateEnum.Toimitettu: {
      return {
        icon: <Check {...styles} />,
        backgroundColor: theme.palette.delivered,
        color: theme.palette.common.black,
      };
    }
    case ShipmentStateEnum.Peruttu: {
      return {
        icon: <Clear {...styles} />,
        backgroundColor: theme.palette.cancelled,
        color: theme.palette.common.black,
      };
    }
    case ShipmentStateEnum.EiVarastossa: {
      return {
        icon: <DoNotDisturb {...styles} />,
        backgroundColor: theme.palette.notInStock,
        color: theme.palette.common.white,
      };
    }
    default: {
      return {
        icon: <AvTimer {...styles} />,
        backgroundColor: theme.palette.common.white,
        color: theme.palette.common.black,
      };
    }
  }
};

export const getShipmentStateChip = (shipment: Shipment) => {
  const stateUtils = getShipmentStateUtils(shipment.state);
  const label = getStateAndTimeStampFromShipment(shipment).formattedText ?? '';
  return (
    <Chip
      sx={{
        background: stateUtils.backgroundColor,
        color: stateUtils.color,
        maxHeight: '1.5rem',
      }}
      icon={stateUtils.icon}
      label={label ? label : shipment.state}
    />
  );
};

export const getShipmentSimpleStateChip = (state: ShipmentStateEnum) => {
  const stateUtils = getShipmentStateUtils(state);
  return (
    <Chip
      sx={{
        background: stateUtils.backgroundColor,
        color: stateUtils.color,
        maxHeight: '1.5rem',
      }}
      icon={stateUtils.icon}
      label={state}
    />
  );
};

export const getShipmentRowStateUtils = (state: ShipmentRowStateEnum): ShipmentStateUtil => {
  const styles = { sx: { fontSize: '0.9rem' } };
  switch (state) {
    case ShipmentRowStateEnum.ReadyForPickup: {
      return {
        icon: <Circle {...styles} />,
        backgroundColor: theme.palette.readyForPickup,
        color: theme.palette.common.black,
      };
    }
    case ShipmentRowStateEnum.PickedUp: {
      return {
        icon: <LocalShipping {...styles} />,
        backgroundColor: theme.palette.pickedUp,
        color: theme.palette.common.black,
      };
    }
    case ShipmentRowStateEnum.Delivered: {
      return {
        icon: <Check {...styles} />,
        backgroundColor: theme.palette.delivered,
        color: theme.palette.common.black,
      };
    }
    case ShipmentRowStateEnum.Failed: {
      return {
        icon: <Clear {...styles} />,
        backgroundColor: theme.palette.cancelled,
        color: theme.palette.common.black,
      };
    }
    case ShipmentRowStateEnum.Lost: {
      return {
        icon: <DoNotDisturb {...styles} />,
        backgroundColor: theme.palette.notInStock,
        color: theme.palette.common.white,
      };
    }
    default: {
      return {
        icon: <Circle {...styles} />,
        backgroundColor: theme.palette.common.white,
        color: theme.palette.common.black,
      };
    }
  }
};

export const getStateAndTimeStampFromShipmentRow = (shipmentRow: ShipmentRow) => {
  const getStateTimeStamp = (): Date | undefined | null => {
    switch (shipmentRow.state) {
      case ShipmentRowStateEnum.Delivered:
        return shipmentRow.delivered_at;
      case ShipmentRowStateEnum.PickedUp:
        return shipmentRow.picked_up_at;
      case ShipmentRowStateEnum.Failed:
        return shipmentRow.failed_at;
      case ShipmentRowStateEnum.Lost:
        return shipmentRow.lost_at;
      default:
        return undefined;
    }
  };

  const timeStampRaw = getStateTimeStamp();
  const timeStamp = timeStampRaw ? timeStampRaw.getTime() : undefined;
  const formattedText = timeStampRaw ? formatShortDateTime(timeStampRaw) : '';

  return { state: shipmentRow.state, timeStamp, formattedText };
};

export const getShipmentRowStateChip = (shipmentRow: ShipmentRow) => {
  const stateUtils = getShipmentRowStateUtils(shipmentRow.state as ShipmentRowStateEnum);
  const label = getStateAndTimeStampFromShipmentRow(shipmentRow).formattedText ?? '';
  return (
    <Chip
      sx={{
        background: stateUtils.backgroundColor,
        color: stateUtils.color,
        maxHeight: '1.5rem',
      }}
      icon={stateUtils.icon}
      label={label ? label : formatShipmentRowState(shipmentRow.state as ShipmentRowStateEnum)}
    />
  );
};

export const canAccessUsers = (currentUser: User | undefined): boolean => {
  return ((currentUser?.is_multi_organization && currentUser?.is_manager) || currentUser?.is_superuser) ?? false;
};

export const canAccessCoordination = (currentUser: User | undefined): boolean => {
  return ((currentUser?.is_coordinator && currentUser?.is_multi_organization) || currentUser?.is_superuser) ?? false;
};

export const canAccessCustomerCoordination = (currentUser: User | undefined): boolean => {
  return (currentUser?.is_coordinator || currentUser?.is_superuser) ?? false;
};

export const canAccessPricing = (currentUser: User | undefined): boolean => {
  return (
    ((currentUser?.organization_id === HOST_ORGANIZATION && currentUser?.is_manager) || currentUser?.is_superuser) ??
    false
  );
};

export const canAccessCustomerPricing = (currentUser?: User): boolean => {
  return (currentUser?.can_access_customer_report || currentUser?.is_superuser) ?? false;
};

export const isHostOrganizationUser = (currentUser?: User): boolean => {
  return currentUser?.organization_id === HOST_ORGANIZATION;
};
