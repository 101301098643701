import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { api, Assignment, FutureWorkHour, FutureWorkHourType } from '../../api';
import { NotificationType } from '../../components/Notification';
import { Loading } from '../../components/Loading';
import { dateFormat, formatFutureWorkHourType, formatWeekDay, timeFormat, timeFormatShort } from '../../formatters';
import { DateTime, Duration } from 'luxon';
import { assignmentIdRequired, dateRequired, timeRequired, typeRequired } from '../../validation';
import MRTEditable, { IsNewAndIsUpdated, ValidationErrors } from '../../components/MaterialReactTable/MRTEditableTable';
import { createMRTColumnHelper, DropdownOption, MRT_TableOptions } from 'material-react-table';
import { MRT_EditCellDatePicker } from '../../components/MaterialReactTable/MRTEditCellDatePicker';
import { MRT_EditCellTextField } from '../../components/MaterialReactTable/MRTEditTextField';
import { omit } from 'lodash';
import {
  AutoCompleteDropdownOption,
  MRT_EditCellAutoComplete,
} from '../../components/MaterialReactTable/MRTEditAutoComplete';
import theme from '../../theme';
import { isEven, isWeekend } from '../../utils';
import { Chip } from '@mui/material';

export const typeIsNormalOrWaiting = (type: FutureWorkHourType) => {
  const typesWithHours = [FutureWorkHourType.Normal, FutureWorkHourType.Waiting];
  return typesWithHours.includes(type);
};

const typeIsTimeOff = (type: FutureWorkHourType) => {
  const timeOffs = [FutureWorkHourType.Pekkanen, FutureWorkHourType.Sick, FutureWorkHourType.Vacation];
  return timeOffs.includes(type);
};

const getDurationInTimeFormat = (value: string) =>
  value.includes('PT') ? Duration.fromISO(value).toFormat(timeFormatShort) : value;

const areAllFieldsValid = (validationErrors: ValidationErrors): boolean => {
  return (
    validationErrors['type']?.value !== FutureWorkHourType.Normal &&
    validationErrors['type']?.value !== FutureWorkHourType.Waiting &&
    !Object.values(omit(validationErrors, ['starts_at', 'ends_at', 'assignment_id'])).some((x) => x && x.error)
  );
};

type FieldName = keyof Pick<FutureWorkHour, 'date' | 'starts_at' | 'ends_at' | 'assignment_id' | 'type'>;

const validateData = (fieldName: FieldName, value: Date | number | FutureWorkHourType | null) => {
  switch (fieldName) {
    case 'date': {
      return dateRequired.validate(DateTime.isDateTime(value) ? value.toJSDate() : value);
    }
    case 'starts_at':
    case 'ends_at': {
      return timeRequired.validate(DateTime.isDateTime(value) ? value.toJSDate() : value);
    }
    case 'assignment_id': {
      return assignmentIdRequired.validate(value);
    }
    case 'type':
      return typeRequired.validate(value);
  }
};

type FutureWorkHourTableProps = {
  employeeName: string | null;
  futureWorkHours: FutureWorkHourWithIsNewAndUpdated[];
  setFutureWorkHours: Dispatch<SetStateAction<FutureWorkHourWithIsNewAndUpdated[]>>;
  setNotification: Dispatch<SetStateAction<NotificationType>>;
  setAreFutureWorkHoursBeingEdited: Dispatch<SetStateAction<boolean>>;
};

export type FutureWorkHourWithIsNewAndUpdated = FutureWorkHour & IsNewAndIsUpdated;

type AssignmentWithOrganizationName = Assignment & { organizationName: string };

const FutureWorkHourTable: React.FC<FutureWorkHourTableProps> = ({
  employeeName,
  futureWorkHours,
  setFutureWorkHours,
  setNotification,
  setAreFutureWorkHoursBeingEdited,
}) => {
  const [assignments, setAssignments] = useState<AssignmentWithOrganizationName[]>([]);

  const loadAssignments = async () => {
    try {
      const organizations = (await api.organizations.getOrganizations({ alsoInactive: false })).data;
      const orgIds = organizations.map((o) => o.id);
      const response = (await api.assignments.getAssignments()).data;
      setAssignments(
        response
          .filter((a) => orgIds.includes(a.organization_id))
          .map((a) => {
            return {
              ...a,
              organizationName: organizations.find((organization) => organization.id === a.organization_id)?.name ?? '',
            };
          }),
      );
    } catch (err) {
      console.error(err);
      setNotification({ message: 'Työntekijöiden lataus epäonnistui!', severity: 'error' });
    }
  };

  useEffect(() => {
    loadAssignments();
  }, []);

  const formatAssignment = (assignmentId: Assignment['assignment_specific_id']) => {
    const assignment = assignments.find((x) => x.assignment_specific_id === assignmentId);
    return `${assignment?.organizationName} - ${assignment?.assignment_specific_id} - ${assignment?.description}`;
  };

  const typeList: DropdownOption[] = Object.values(FutureWorkHourType)
    .map((value) => ({
      label: formatFutureWorkHourType(value),
      value: value,
    }))
    //partial_sick isn't being used yet
    .filter((x) => x.value !== FutureWorkHourType.PartialSick);

  const assingmentList: AutoCompleteDropdownOption[] = assignments.map((value) => ({
    label: formatAssignment(value.assignment_specific_id),
    value: value.assignment_specific_id,
  }));

  const columnHelper = createMRTColumnHelper<FutureWorkHourWithIsNewAndUpdated>();

  const columns = useMemo(
    () => [
      columnHelper.accessor((row) => (row.date ? DateTime.fromJSDate(row.date) : row.date), {
        id: 'date',
        header: 'Päivämäärä',
        filterFn: 'dateFilterFn',
        filterVariant: 'date',
        Cell: ({ cell }) => cell.getValue<DateTime>().toFormat(dateFormat),
        Edit: ({ cell, table }) => <MRT_EditCellDatePicker cell={cell} table={table} />,
      }),
      columnHelper.accessor((row) => (row.date ? DateTime.fromJSDate(row.date) : row.date), {
        id: 'day',
        header: 'Päivä',
        filterFn: 'dateFilterFn',
        filterVariant: 'date',
        Cell: ({ cell }) => formatWeekDay(cell.getValue<DateTime>()),
        enableEditing: false,
        size: 60,
      }),
      columnHelper.accessor('type', {
        id: 'type',
        header: 'Tyyppi',
        editVariant: 'select',
        editSelectOptions: typeList,
        Edit: ({ cell, table }) => <MRT_EditCellTextField cell={cell} table={table} />,
        Cell: ({ cell }) => {
          const value = cell.getValue();
          const type = formatFutureWorkHourType(value);
          const backgroundColor =
            value === FutureWorkHourType.Vacation
              ? theme.palette.holiday
              : value === FutureWorkHourType.Pekkanen
                ? theme.palette.dayOff
                : value === FutureWorkHourType.Sick
                  ? theme.palette.sickLeave
                  : undefined;
          return typeIsTimeOff(value) ? (
            <Chip sx={{ background: backgroundColor, maxHeight: '1.5rem' }} label={type} />
          ) : (
            type
          );
        },
      }),
      columnHelper.accessor((row) => (row.starts_at ? DateTime.fromJSDate(row.starts_at) : row.starts_at), {
        id: 'starts_at',
        header: 'Aloitus',
        Cell: ({ cell, row }) =>
          typeIsNormalOrWaiting(row.renderValue('type')) &&
          (cell.getValue() ? cell.getValue<DateTime>().toFormat(timeFormat) : ''),
        Edit: ({ cell, table }) => <MRT_EditCellDatePicker cell={cell} table={table} time={true} />,
        enableEditing: (row) => typeIsNormalOrWaiting(row.renderValue('type')),
        size: 60,
      }),
      columnHelper.accessor((row) => (row.ends_at ? DateTime.fromJSDate(row.ends_at) : row.ends_at), {
        id: 'ends_at',
        header: 'Lopetus',
        Cell: ({ cell, row }) =>
          typeIsNormalOrWaiting(row.renderValue('type')) &&
          (cell.getValue() ? cell.getValue<DateTime>().toFormat(timeFormat) : ''),
        Edit: ({ cell, table }) => <MRT_EditCellDatePicker cell={cell} table={table} time={true} />,
        enableEditing: (row) => typeIsNormalOrWaiting(row.renderValue('type')),
        size: 60,
      }),
      columnHelper.accessor('assignment_id', {
        id: 'assignment_id',
        header: 'Työ',
        editSelectOptions: assingmentList,
        Edit: ({ cell, table }) => <MRT_EditCellAutoComplete cell={cell} table={table} />,
        Cell: ({ cell, row }) =>
          typeIsNormalOrWaiting(row.renderValue('type')) && formatAssignment(cell.getValue<number>()),
        enableEditing: (row) => typeIsNormalOrWaiting(row.renderValue('type')),
      }),
      columnHelper.accessor('note', {
        header: 'Selite',
        Cell: ({ cell, row }) => !typeIsTimeOff(row.renderValue('type')) && cell.getValue<string>(),
        enableEditing: (row) => !typeIsTimeOff(row.renderValue('type')),
      }),
      columnHelper.accessor((row) => getDurationInTimeFormat(row.net_hours), {
        id: 'net_hours',
        header: 'Netto',
        enableEditing: false,
        size: 60,
      }),
      columnHelper.accessor((row) => getDurationInTimeFormat(row.evening_hours), {
        id: 'evening_hours',
        header: 'Ilta',
        enableEditing: false,
        size: 60,
      }),
      columnHelper.accessor((row) => getDurationInTimeFormat(row.night_hours), {
        id: 'night_hours',
        header: 'Yö',
        enableEditing: false,
        size: 60,
      }),
    ],
    [assignments, futureWorkHours],
  );

  const handleCreateUpdateDelete = (futureWorkHours: FutureWorkHourWithIsNewAndUpdated[]) =>
    setFutureWorkHours(futureWorkHours);

  const tableOptions: MRT_TableOptions<FutureWorkHour> = {
    data: futureWorkHours,
    columns: columns,
    initialState: {
      sorting: [{ id: 'date', desc: false }],
      pagination: {
        pageSize: 100,
        pageIndex: 0,
      },
    },
    muiTableBodyRowProps: ({ row }) => {
      const date = row.original.date;
      const dateTime = DateTime.fromJSDate(date);
      return {
        sx: {
          backgroundColor: isWeekend(dateTime)
            ? theme.palette.primary.light
            : (isEven(dateTime.weekNumber) && isEven(dateTime.weekday)) ||
                (!isEven(dateTime.weekNumber) && !isEven(dateTime.weekday))
              ? theme.palette.action.hover
              : undefined,
        },
      };
    },
  };

  if (assignments.length === 0) {
    return <Loading isLoading={true} />;
  }

  return (
    <>
      <div style={{ margin: '0.5rem' }}>
        <MRTEditable
          header={`Työntekijä ${employeeName} tunnit`}
          validateData={validateData}
          handleCreate={handleCreateUpdateDelete}
          handleSave={handleCreateUpdateDelete}
          handleDelete={handleCreateUpdateDelete}
          customValidation={areAllFieldsValid}
          setIsEditing={setAreFutureWorkHoursBeingEdited}
          {...tableOptions}
        />
      </div>
    </>
  );
};

export default FutureWorkHourTable;
