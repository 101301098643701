import React, { useEffect, useState } from 'react';
import { Box, IconButton, Tooltip } from '@mui/material';
import {
  MaterialReactTable,
  MRT_TableOptions,
  useMaterialReactTable,
  MRT_ColumnDef,
  MRT_RowData,
  MRT_Row,
  createRow,
} from 'material-react-table';
import { DateTime } from 'luxon';
import { Add, Cancel, Delete, Edit } from '@mui/icons-material';
import { MRT_EditActionButtons } from './MRTEditActionButtons';
import { MRT_Localization_FI } from 'material-react-table/locales/fi';
import { customDateTime, getTooltipFromTable } from './MRT';
import theme from '../../theme';

export type IsNewAndIsUpdated = {
  isNew?: boolean;
  isUpdated?: boolean;
};

type ValidationResult = {
  error: any;
  value: any;
};

export type ValidationErrors = { [key: string]: ValidationResult | undefined };

export interface Props<TData extends MRT_RowData> extends MRT_TableOptions<TData> {
  columns: MRT_ColumnDef<TData>[];
  data: TData[];
  header: string;
  validateData?: (fieldName: any, value: any) => ValidationResult | undefined;
  handleCreate?: (values: TData[]) => void;
  handleSave?: (values: TData[]) => void;
  handleDelete?: (values: TData[]) => void;
  setIsEditing?: (valid: boolean) => void;
  customValidation?: (validationErrors: ValidationErrors) => boolean;
  createRowDefaultValues?: Partial<TData>;
  rowSelection?: boolean;
  customRowActions?: (row: MRT_Row<TData>) => React.ReactNode | null;
}

const areAllFieldsValid = (validationErrors: ValidationErrors): boolean => {
  return !Object.values(validationErrors).some((x) => x && x.error);
};

export const MRTEditable = <TData extends MRT_RowData>({
  columns,
  data,
  header,
  validateData,
  handleCreate,
  handleSave,
  handleDelete,
  setIsEditing,
  customValidation,
  createRowDefaultValues,
  rowSelection,
  customRowActions,
  ...rest
}: Props<TData>) => {
  const [validationErrors, setValidationErrors] = useState<ValidationErrors>({});
  const [deletingRow, setDeletingRow] = useState<MRT_Row<TData> | null>(null);

  const handleCancel: MRT_TableOptions<TData>['onCreatingRowCancel' | 'onEditingRowCancel'] = ({ table }) => {
    setValidationErrors({});
    table.setCreatingRow(null);
    table.setEditingRow(null);
  };

  const handleCreateInner: MRT_TableOptions<TData>['onCreatingRowSave'] = ({ values, table, row }) => {
    if (!handleCreate) {
      return;
    }
    setValidationErrors({});

    const updatedData = [...data];
    const tempId = Math.random() + updatedData.length;
    const formattedValues = Object.fromEntries(
      Object.entries(values).map(([k, v]) => [k, DateTime.isDateTime(v) ? v.toJSDate() : v]),
    );
    updatedData.unshift({ ...row.original, ...formattedValues, id: tempId, isNew: true });

    handleCreate(updatedData);

    table.setCreatingRow(null);
  };

  const handleSaveInner: MRT_TableOptions<TData>['onEditingRowSave'] = ({ values, table, row }) => {
    if (!handleSave) {
      return;
    }

    setValidationErrors({});

    const updatedData = [...data];
    const rowToUpdateIndex = updatedData.find((x) => x.id === row.id);
    const formattedValues = Object.fromEntries(
      Object.entries(values).map(([k, v]) => [k, DateTime.isDateTime(v) ? v.toJSDate() : v]),
    );
    if (rowToUpdateIndex) {
      const index = updatedData.indexOf(rowToUpdateIndex);
      updatedData[index] = {
        ...row.original,
        ...formattedValues,
        isUpdated: true,
      };
    }
    handleSave(updatedData);

    table.setEditingRow(null);
  };

  const handleDeleteInner = (row: TData) => {
    if (!handleDelete) {
      return;
    }
    const updatedData = [...data].filter((x) => x.id !== row.id);

    handleDelete(updatedData);
    setDeletingRow(null);
  };

  const handleDeleteSelectedInner = () => {
    if (!handleDelete) {
      return;
    }
    const selectedShipmentRows = table.getSelectedRowModel().rows.map((x) => x.original);
    const dataToDelete = data.filter((item) => selectedShipmentRows.indexOf(item) === -1);
    handleDelete(dataToDelete);
    setDeletingRow(null);
    table.setCreatingRow(null);
    table.setEditingRow(null);
    table.resetRowSelection();
  };

  const table = useMaterialReactTable({
    columns,
    data,
    createDisplayMode: 'row',
    editDisplayMode: 'row',
    positionActionsColumn: 'last',
    layoutMode: 'grid',
    enableEditing: true,
    enableFilters: false,
    enableHiding: false,
    enableFullScreenToggle: false,
    enableColumnActions: false,
    enableRowSelection: rowSelection ?? false,
    enableKeyboardShortcuts: false,
    localization: MRT_Localization_FI,
    positionToolbarAlertBanner: 'none',
    sortingFns: { customDateTime: customDateTime },
    filterFns: {
      dateFilterFn: (row, id, filterValue) => {
        return row.getValue<DateTime>(id).startOf('day').equals(DateTime.fromISO(filterValue));
      },
    },
    getRowId: (row) => row.id,
    onCreatingRowCancel: handleCancel,
    onCreatingRowSave: handleCreateInner,
    onEditingRowCancel: handleCancel,
    onEditingRowSave: handleSaveInner,
    displayColumnDefOptions: {
      'mrt-row-actions': {
        muiTableBodyCellProps: {
          align: 'right',
        },
        size: 60,
        Cell: ({ row, table }) => {
          const { getState, setEditingRow } = table;
          const { editingRow, creatingRow } = getState();
          const isEditing = Boolean(creatingRow || editingRow || deletingRow);
          return row.original.enableEditing === false ? (
            <></>
          ) : creatingRow?.id === row.id || editingRow?.id === row.id ? (
            <MRT_EditActionButtons
              row={row}
              table={table}
              isValid={(customValidation && customValidation(validationErrors)) || areAllFieldsValid(validationErrors)}
              isLoading={false}
            />
          ) : deletingRow?.id === row.id ? (
            <div style={{ whiteSpace: 'nowrap' }}>
              <Tooltip title="Poista">
                <span>
                  <IconButton
                    data-cy="confirm-delete-row-button"
                    size="small"
                    disabled={!isEditing}
                    color="error"
                    onClick={() => handleDeleteInner(row.original)}
                  >
                    <Delete />
                  </IconButton>
                </span>
              </Tooltip>
              <Tooltip title="Peruuta">
                <span>
                  <IconButton
                    data-cy="cancel-delete-row-button"
                    size="small"
                    disabled={!isEditing}
                    onClick={() => setDeletingRow(null)}
                  >
                    <Cancel />
                  </IconButton>
                </span>
              </Tooltip>
            </div>
          ) : (
            <div style={{ whiteSpace: 'nowrap' }}>
              {customRowActions && customRowActions(row)}
              {handleSave && (
                <Tooltip title="Muokkaa">
                  <span>
                    <IconButton
                      data-cy="edit-row-button"
                      size="small"
                      disabled={isEditing}
                      onClick={() => {
                        const newErrors = columns.reduce(
                          (obj, item) => ({
                            ...obj,
                            [item.id as keyof TData]:
                              validateData && validateData(item.id, row.original[item.id as keyof TData]),
                          }),
                          {},
                        );
                        setValidationErrors(newErrors);
                        setEditingRow(row);
                      }}
                    >
                      <Edit />
                    </IconButton>
                  </span>
                </Tooltip>
              )}
              {handleDelete && (
                <Tooltip title="Poista">
                  <span>
                    <IconButton
                      data-cy="delete-row-button"
                      size="small"
                      disabled={isEditing}
                      color="error"
                      onClick={() => setDeletingRow(row)}
                    >
                      <Delete />
                    </IconButton>
                  </span>
                </Tooltip>
              )}
            </div>
          );
        },
      },
    },
    renderTopToolbarCustomActions: ({ table }) => {
      const { getState, setCreatingRow } = table;
      const { editingRow, creatingRow } = getState();
      const isEditing = Boolean(creatingRow || editingRow || deletingRow);
      const selectedShipmentRows = table.getSelectedRowModel().rows.map((x) => x);

      return (
        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          <legend style={{ fontWeight: 'bold' }}>{header}</legend>
          <Box>
            {rowSelection && selectedShipmentRows.length > 0 && (
              <Tooltip title={`Poista valitut (${selectedShipmentRows.length} kpl)`}>
                <span>
                  <IconButton
                    data-cy="delete-selected-rows-button"
                    size="small"
                    disabled={selectedShipmentRows.length === 0}
                    color="error"
                    onClick={handleDeleteSelectedInner}
                  >
                    <Delete />
                  </IconButton>
                </span>
              </Tooltip>
            )}
            {handleCreate && (
              <Tooltip title="Lisää">
                <span>
                  <IconButton
                    data-cy="add-row-button"
                    size="small"
                    color="success"
                    disabled={isEditing}
                    onClick={() => {
                      const newErrors = columns.reduce((obj, item) => {
                        const key = item.id as keyof TData;
                        const initialValue =
                          createRowDefaultValues && createRowDefaultValues[key] ? createRowDefaultValues[key] : '';
                        return {
                          ...obj,
                          [key]: validateData && validateData(item.id, initialValue),
                        };
                      }, {});
                      setValidationErrors(newErrors);
                      if (createRowDefaultValues) {
                        setCreatingRow(createRow(table, createRowDefaultValues as TData));
                      } else {
                        setCreatingRow(true);
                      }
                    }}
                  >
                    <Add />
                  </IconButton>
                </span>
              </Tooltip>
            )}
          </Box>
        </Box>
      );
    },
    defaultColumn: {
      size: 100,
      muiEditTextFieldProps: ({ column }) => {
        return {
          error: !!validationErrors?.[column.id]?.error?.message,
          helperText: validationErrors?.[column.id]?.error?.message,
          onChange: (event) => {
            setValidationErrors({
              ...validationErrors,
              ...{ [event.target.name]: validateData && validateData(event.target.name, event.target.value) },
            });
          },
        };
      },
    },
    muiTablePaperProps: {
      sx: {
        border: `1px solid ${theme.palette.table.main}`,
      },
      elevation: 0,
    },
    muiTableBodyCellProps: ({ table, row, cell, column }) => getTooltipFromTable({ table, row, cell, column }),
    mrtTheme: (theme) => ({
      baseBackgroundColor: theme.palette.common.white,
      menuBackgroundColor: theme.palette.common.white,
      pinnedRowBackgroundColor: theme.palette.common.white,
    }),
    ...rest,
    initialState: {
      density: 'compact',
    },
  });

  const { creatingRow, editingRow } = table.getState();

  useEffect(() => {
    if (setIsEditing) {
      setIsEditing(Boolean(creatingRow || editingRow || deletingRow));
    }
  }, [creatingRow || editingRow || deletingRow]);

  return <MaterialReactTable table={table} />;
};

export default MRTEditable;
