import { type FocusEvent, SyntheticEvent, useState } from 'react';
import TextField from '@mui/material/TextField';
import { type TextFieldProps } from '@mui/material/TextField';
import { parseFromValuesOrFunc } from './utils';
import { MRT_RowData, MRT_Cell, MRT_TableInstance } from 'material-react-table';
import React from 'react';
import { Autocomplete } from '@mui/material';

export interface MRT_EditCellTextFieldProps<TData extends MRT_RowData> extends TextFieldProps<'standard'> {
  cell: MRT_Cell<TData, any>;
  table: MRT_TableInstance<TData>;
}

export type AutoCompleteDropdownOption = {
  label: string;
  value: string | number | null;
};

export const MRT_EditCellAutoComplete = <TData extends MRT_RowData>({
  cell,
  table,
  ...rest
}: MRT_EditCellTextFieldProps<TData>) => {
  const {
    getState,
    options: { createDisplayMode, editDisplayMode, muiEditTextFieldProps },
    refs: { editInputRefs },
    setCreatingRow,
    setEditingCell,
    setEditingRow,
  } = table;
  const { column, row } = cell;
  const { columnDef } = column;
  const { creatingRow, editingRow } = getState();
  const { editSelectOptions } = columnDef;

  const isCreating = creatingRow?.id === row.id;
  const isEditing = editingRow?.id === row.id;

  const [value, setValue] = useState(() => cell.getValue<string | number | null>());

  const textFieldProps: TextFieldProps = {
    ...parseFromValuesOrFunc(muiEditTextFieldProps, {
      cell,
      column,
      row,
      table,
    }),
    ...parseFromValuesOrFunc(columnDef.muiEditTextFieldProps, {
      cell,
      column,
      row,
      table,
    }),
    ...rest,
  };

  const selectOptions = parseFromValuesOrFunc(editSelectOptions, {
    cell,
    column,
    row,
    table,
  });

  const saveInputValueToRowCache = (newValue: string | number | null) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    row._valuesCache[column.id] = newValue;

    if (isCreating) {
      setCreatingRow(row);
    } else if (isEditing) {
      setEditingRow(row);
    }
  };

  const handleChange = (event: SyntheticEvent<Element, Event>, option: AutoCompleteDropdownOption | null) => {
    const value = option ? option.value : option;
    textFieldProps.onChange?.({
      ...event,
      target: { ...event.target, value: value, name: column.id },
    } as any);
    setValue(value);
    saveInputValueToRowCache(value);
  };

  const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
    textFieldProps.onBlur?.(event);
    saveInputValueToRowCache(value);
    setEditingCell(null);
  };

  const options: AutoCompleteDropdownOption[] = (selectOptions ?? []) as AutoCompleteDropdownOption[];

  return (
    <Autocomplete
      fullWidth
      options={options}
      autoHighlight
      getOptionLabel={(option) => option.label ?? null}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      renderOption={(props, option) => <li {...props}>{option.label}</li>}
      renderInput={(params) => (
        <TextField
          {...params}
          inputRef={(inputRef) => {
            if (inputRef) {
              editInputRefs.current![column.id] = inputRef;
              if (textFieldProps.inputRef) {
                textFieldProps.inputRef = inputRef;
                textFieldProps.name = column.id;
              }
            }
          }}
          label={
            ['custom', 'modal'].includes((isCreating ? createDisplayMode : editDisplayMode) as string)
              ? columnDef.header
              : undefined
          }
          margin="none"
          name={column.id}
          placeholder={
            !['custom', 'modal'].includes((isCreating ? createDisplayMode : editDisplayMode) as string)
              ? columnDef.header
              : undefined
          }
          size="small"
          {...textFieldProps}
          helperText={textFieldProps.helperText}
          error={textFieldProps.error}
          inputProps={{
            ...params.inputProps,
            name: column.id,
            autoComplete: 'new-password', // disable autocomplete and autofill
          }}
        />
      )}
      onBlur={handleBlur}
      value={options.find((option) => option.value === value) ?? null}
      onChange={handleChange}
      onClick={(e) => {
        e.stopPropagation();
        textFieldProps?.onClick?.(e);
      }}
    />
  );
};
