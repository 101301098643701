/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import { CarType, CarTypeFromJSON, CarTypeFromJSONTyped, CarTypeToJSON } from './CarType';

/**
 * The template for omitting properties.
 * @export
 * @interface CarrierCarPostBody
 */
export interface CarrierCarPostBody {
  /**
   *
   * @type {string}
   * @memberof CarrierCarPostBody
   */
  licence_plate: string;
  /**
   *
   * @type {boolean}
   * @memberof CarrierCarPostBody
   */
  is_active: boolean;
  /**
   *
   * @type {number}
   * @memberof CarrierCarPostBody
   */
  default_driver_id: number | null;
  /**
   *
   * @type {string}
   * @memberof CarrierCarPostBody
   */
  car_identifier: string | null;
  /**
   *
   * @type {number}
   * @memberof CarrierCarPostBody
   */
  car_number: number | null;
  /**
   *
   * @type {string}
   * @memberof CarrierCarPostBody
   */
  manufacturer: string;
  /**
   *
   * @type {string}
   * @memberof CarrierCarPostBody
   */
  model: string;
  /**
   *
   * @type {CarType}
   * @memberof CarrierCarPostBody
   */
  type: CarType;
  /**
   *
   * @type {string}
   * @memberof CarrierCarPostBody
   */
  imei: string | null;
  /**
   *
   * @type {string}
   * @memberof CarrierCarPostBody
   */
  vin_number: string | null;
  /**
   *
   * @type {Date}
   * @memberof CarrierCarPostBody
   */
  first_registration_date: Date | null;
  /**
   *
   * @type {Date}
   * @memberof CarrierCarPostBody
   */
  vehicle_inspection_date: Date | null;
  /**
   *
   * @type {string}
   * @memberof CarrierCarPostBody
   */
  transport_licence: string | null;
  /**
   *
   * @type {Date}
   * @memberof CarrierCarPostBody
   */
  transport_licence_date: Date | null;
  /**
   *
   * @type {string}
   * @memberof CarrierCarPostBody
   */
  tyres: string | null;
  /**
   *
   * @type {string}
   * @memberof CarrierCarPostBody
   */
  hoist: string | null;
  /**
   *
   * @type {string}
   * @memberof CarrierCarPostBody
   */
  platform_details: string | null;
  /**
   *
   * @type {boolean}
   * @memberof CarrierCarPostBody
   */
  is_adr: boolean;
  /**
   *
   * @type {number}
   * @memberof CarrierCarPostBody
   */
  unladen_weight: number | null;
  /**
   *
   * @type {number}
   * @memberof CarrierCarPostBody
   */
  gross_weight: number | null;
  /**
   *
   * @type {number}
   * @memberof CarrierCarPostBody
   */
  height: number | null;
  /**
   *
   * @type {string}
   * @memberof CarrierCarPostBody
   */
  fuel_card: string | null;
  /**
   *
   * @type {string}
   * @memberof CarrierCarPostBody
   */
  fuel_id: string | null;
  /**
   *
   * @type {Date}
   * @memberof CarrierCarPostBody
   */
  hoist_inspection_date: Date | null;
  /**
   *
   * @type {string}
   * @memberof CarrierCarPostBody
   */
  note: string | null;
  /**
   *
   * @type {boolean}
   * @memberof CarrierCarPostBody
   */
  is_coordination: boolean;
  /**
   *
   * @type {number}
   * @memberof CarrierCarPostBody
   */
  default_trailer_id: number | null;
}

export function CarrierCarPostBodyFromJSON(json: any): CarrierCarPostBody {
  return CarrierCarPostBodyFromJSONTyped(json, false);
}

export function CarrierCarPostBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): CarrierCarPostBody {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    licence_plate: json['licence_plate'],
    is_active: json['is_active'],
    default_driver_id: json['default_driver_id'],
    car_identifier: json['car_identifier'],
    car_number: json['car_number'],
    manufacturer: json['manufacturer'],
    model: json['model'],
    type: CarTypeFromJSON(json['type']),
    imei: json['imei'],
    vin_number: json['vin_number'],
    first_registration_date:
      json['first_registration_date'] === null ? null : new Date(json['first_registration_date']),
    vehicle_inspection_date:
      json['vehicle_inspection_date'] === null ? null : new Date(json['vehicle_inspection_date']),
    transport_licence: json['transport_licence'],
    transport_licence_date: json['transport_licence_date'] === null ? null : new Date(json['transport_licence_date']),
    tyres: json['tyres'],
    hoist: json['hoist'],
    platform_details: json['platform_details'],
    is_adr: json['is_adr'],
    unladen_weight: json['unladen_weight'],
    gross_weight: json['gross_weight'],
    height: json['height'],
    fuel_card: json['fuel_card'],
    fuel_id: json['fuel_id'],
    hoist_inspection_date: json['hoist_inspection_date'] === null ? null : new Date(json['hoist_inspection_date']),
    note: json['note'],
    is_coordination: json['is_coordination'],
    default_trailer_id: json['default_trailer_id'],
  };
}

export function CarrierCarPostBodyToJSON(value?: CarrierCarPostBody | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    licence_plate: value.licence_plate,
    is_active: value.is_active,
    default_driver_id: value.default_driver_id,
    car_identifier: value.car_identifier,
    car_number: value.car_number,
    manufacturer: value.manufacturer,
    model: value.model,
    type: CarTypeToJSON(value.type),
    imei: value.imei,
    vin_number: value.vin_number,
    first_registration_date:
      value.first_registration_date === null ? null : value.first_registration_date.toISOString().substr(0, 10),
    vehicle_inspection_date:
      value.vehicle_inspection_date === null ? null : value.vehicle_inspection_date.toISOString().substr(0, 10),
    transport_licence: value.transport_licence,
    transport_licence_date:
      value.transport_licence_date === null ? null : value.transport_licence_date.toISOString().substr(0, 10),
    tyres: value.tyres,
    hoist: value.hoist,
    platform_details: value.platform_details,
    is_adr: value.is_adr,
    unladen_weight: value.unladen_weight,
    gross_weight: value.gross_weight,
    height: value.height,
    fuel_card: value.fuel_card,
    fuel_id: value.fuel_id,
    hoist_inspection_date:
      value.hoist_inspection_date === null ? null : value.hoist_inspection_date.toISOString().substr(0, 10),
    note: value.note,
    is_coordination: value.is_coordination,
    default_trailer_id: value.default_trailer_id,
  };
}
