import { Button, ButtonProps, Tooltip } from '@mui/material';
import { Save } from '@mui/icons-material';
import React from 'react';

export interface SaveButtonProps extends ButtonProps {
  tooltip?: string;
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export const SaveButton: React.FC<SaveButtonProps> = ({ tooltip, onClick, className, children, ...buttonProps }) => (
  <Tooltip title={tooltip ?? ''} aria-label={tooltip ?? ''} data-cy="save-button">
    <span style={{ alignContent: 'center' }}>
      {/* This span makes Tooltip work with disabled SaveButton. Disabled elements do not send events, so we need this wrapper */}
      <Button
        className={className + ' save-button'}
        startIcon={<Save />}
        color="success"
        onClick={onClick}
        {...buttonProps}
      >
        {children ? children : 'Tallenna'}
      </Button>
    </span>
  </Tooltip>
);
