/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface CarrierLoadPutBody
 */
export interface CarrierLoadPutBody {
  /**
   *
   * @type {string}
   * @memberof CarrierLoadPutBody
   */
  organization_id: string | null;
  /**
   *
   * @type {number}
   * @memberof CarrierLoadPutBody
   */
  car_id: number | null;
  /**
   *
   * @type {Date}
   * @memberof CarrierLoadPutBody
   */
  drive_date: Date | null;
  /**
   *
   * @type {string}
   * @memberof CarrierLoadPutBody
   */
  state: CarrierLoadPutBodyStateEnum;
  /**
   *
   * @type {number}
   * @memberof CarrierLoadPutBody
   */
  driver_id: number | null;
  /**
   *
   * @type {Date}
   * @memberof CarrierLoadPutBody
   */
  picked_up_at: Date | null;
  /**
   *
   * @type {number}
   * @memberof CarrierLoadPutBody
   */
  assignment_id?: number;
  /**
   *
   * @type {string}
   * @memberof CarrierLoadPutBody
   */
  note: string | null;
  /**
   *
   * @type {number}
   * @memberof CarrierLoadPutBody
   */
  trailer_id: number | null;
  /**
   *
   * @type {Date}
   * @memberof CarrierLoadPutBody
   */
  driver_started_at: Date | null;
  /**
   *
   * @type {Date}
   * @memberof CarrierLoadPutBody
   */
  driver_ended_at: Date | null;
}

/**
 * @export
 * @enum {string}
 */
export enum CarrierLoadPutBodyStateEnum {
  Uusi = 'uusi',
  Jarjestelty = 'jarjestelty',
  Noudettu = 'noudettu',
  Toimitettu = 'toimitettu',
  Laskutettavissa = 'laskutettavissa',
  Laskutettu = 'laskutettu',
}

export function CarrierLoadPutBodyFromJSON(json: any): CarrierLoadPutBody {
  return CarrierLoadPutBodyFromJSONTyped(json, false);
}

export function CarrierLoadPutBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): CarrierLoadPutBody {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    organization_id: json['organization_id'],
    car_id: json['car_id'],
    drive_date: json['drive_date'] === null ? null : new Date(json['drive_date']),
    state: json['state'],
    driver_id: json['driver_id'],
    picked_up_at: json['picked_up_at'] === null ? null : new Date(json['picked_up_at']),
    assignment_id: !exists(json, 'assignment_id') ? undefined : json['assignment_id'],
    note: json['note'],
    trailer_id: json['trailer_id'],
    driver_started_at: json['driver_started_at'] === null ? null : new Date(json['driver_started_at']),
    driver_ended_at: json['driver_ended_at'] === null ? null : new Date(json['driver_ended_at']),
  };
}

export function CarrierLoadPutBodyToJSON(value?: CarrierLoadPutBody | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    organization_id: value.organization_id,
    car_id: value.car_id,
    drive_date: value.drive_date === null ? null : value.drive_date.toISOString().substr(0, 10),
    state: value.state,
    driver_id: value.driver_id,
    picked_up_at: value.picked_up_at === null ? null : value.picked_up_at.toISOString(),
    assignment_id: value.assignment_id,
    note: value.note,
    trailer_id: value.trailer_id,
    driver_started_at: value.driver_started_at === null ? null : value.driver_started_at.toISOString(),
    driver_ended_at: value.driver_ended_at === null ? null : value.driver_ended_at.toISOString(),
  };
}
