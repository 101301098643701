import { useState } from 'react';
import { type TextFieldProps } from '@mui/material/TextField';
import { MRT_Cell, MRT_RowData, MRT_TableInstance } from 'material-react-table';
import React from 'react';
import { DateTime } from 'luxon';
import { dateFormat, timeFormat } from '../../formatters';
import { DatePicker, DatePickerProps, TimePicker } from '@mui/x-date-pickers';

export interface MRT_MRT_EditCellDatePickerProps<TData extends MRT_RowData> extends DatePickerProps<DateTime> {
  cell: MRT_Cell<TData, any>;
  table: MRT_TableInstance<TData>;
  time?: boolean;
}

export const MRT_EditCellDatePicker = <TData extends Record<string, any>>({
  cell,
  table,
  time,
}: MRT_MRT_EditCellDatePickerProps<TData>) => {
  const {
    options: { muiEditTextFieldProps },
    refs: { editInputRefs },
  } = table as any;
  const { column, row } = cell;
  const { columnDef } = column;

  const mTableBodyCellEditTextFieldProps =
    muiEditTextFieldProps instanceof Function
      ? muiEditTextFieldProps({ cell, column, row, table })
      : muiEditTextFieldProps;

  const mcTableBodyCellEditTextFieldProps =
    (columnDef as any).muiEditTextFieldProps instanceof Function
      ? (columnDef as any).muiEditTextFieldProps({
          cell,
          column,
          row,
          table,
        })
      : (columnDef as any).muiEditTextFieldProps;

  const textFieldProps: TextFieldProps = {
    ...mTableBodyCellEditTextFieldProps,
    ...mcTableBodyCellEditTextFieldProps,
  } as any;

  const [value, setValue] = useState(cell.getValue<DateTime>() ? cell.getValue<DateTime>() : null);

  const saveInputValueToRowCache = (newValue: string) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    row._valuesCache[column.id] = newValue;
  };

  const handleChange = (newValue: any) => {
    textFieldProps.onChange?.({ target: { name: column.id, value: newValue } } as any);
    setValue(newValue);
    saveInputValueToRowCache(newValue);
  };

  const { onError, slots, ...restOfProps } = textFieldProps;

  const Picker = time ? TimePicker : DatePicker;
  const format = time ? timeFormat : dateFormat;

  return (
    <Picker
      {...restOfProps}
      sx={{
        '& .MuiInputBase-input': {
          padding: 0,
        },
      }}
      key={column.id}
      format={format}
      value={value}
      disabled={
        (columnDef.enableEditing instanceof Function ? columnDef.enableEditing(row) : columnDef.enableEditing) === false
      }
      inputRef={(inputRef) => {
        if (inputRef) {
          editInputRefs.current[column.id] = inputRef;
          if (textFieldProps.inputRef) {
            textFieldProps.inputRef = inputRef as any;
          }
        }
      }}
      slotProps={{
        textField: {
          name: column.id,
          helperText: textFieldProps.helperText,
          error: textFieldProps.error,
        },
      }}
      onChange={handleChange}
    />
  );
};
