import { styled, Typography } from '@mui/material';
import React from 'react';

const Container = styled('header')({
  padding: '0.5rem',
  display: 'flex',
  flexFlow: 'row wrap',
  flexGrow: 1,
});

const Title = styled(Typography)({
  marginRight: '1rem',
});

interface HeaderProps extends React.HTMLAttributes<HTMLElement> {
  title: string;
}

export const Header: React.FC<HeaderProps> = ({ title, children }) => {
  return (
    <Container>
      <Title variant="h1">{title}</Title>
      {children}
    </Container>
  );
};
