import { Alert } from '@mui/material';
import React from 'react';
import { formatDateTime } from '../../../formatters';

interface UpdateInfoProps {
  updated_by: string | null;
  updated_at: Date | null;
}

export const UpdateInfo: React.FC<UpdateInfoProps> = ({ updated_by, updated_at }) => (
  <Alert severity="info" sx={{ alignItems: 'center', '& > div': { padding: 0 } }}>
    <div data-cy="updated-by-field">
      Muokkaaja <strong>{updated_by ? updated_by : '-'}</strong>
    </div>
    <div data-cy="updated-at-field">{updated_at ? formatDateTime(updated_at) : '-'}</div>
  </Alert>
);
