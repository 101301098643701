/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum CarType {
  Kaappi = 'kaappi',
  Pikkuauto = 'pikkuauto',
  Pikkupilkki = 'pikkupilkki',
  Erikoispilkki = 'erikoispilkki',
  Lavetti = 'lavetti',
  Pervaunu = 'perävaunu',
  _3AksPilkki = '3-aks pilkki',
  _2AksPilkki = '2-aks pilkki',
  _4AksPilkki = '4-aks pilkki',
}

export function CarTypeFromJSON(json: any): CarType {
  return CarTypeFromJSONTyped(json, false);
}

export function CarTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): CarType {
  return json as CarType;
}

export function CarTypeToJSON(value?: CarType | null): any {
  return value as any;
}
