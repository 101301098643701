import { Shipment, ShipmentStateEnum } from '../api';
import React, { useMemo, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Link, Chip, IconButton, Input, Menu } from '@mui/material';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import { AddressLink } from './map';
import { getShipmentStateUtils, getStateAndTimeStampFromShipment, sortShipmentByStateAndTimestamp } from '../utils';
import { DeliveryWindow } from './DeliveryWindow';
import { createMRTColumnHelper, MRT_TableOptions } from 'material-react-table';
import { useCurrentUser } from '../hooks/useCurrentUser';
import MRTEditable from './MaterialReactTable/MRTEditableTable';
import { ShipmentRowSubRowAsync } from './MaterialReactTable/MRTShipmentRowSubRow';
import { orderBy } from 'lodash';
import { formatTime } from '../formatters';

interface LoadShipmentsTableProps {
  shipments: Shipment[];
  isLoading: boolean;
  onShipmentUpdate: (shipments: Shipment[]) => void;
}

export const LoadShipmentsTable: React.FC<LoadShipmentsTableProps> = ({ shipments, onShipmentUpdate }) => {
  const currentUser = useCurrentUser();
  const data = useMemo(() => orderBy(shipments, 'order_in_load'), [shipments]);

  const columnHelper = createMRTColumnHelper<Shipment>();

  const updateRowOrder = (newIndex: number, oldIndex: number) => {
    const updateData = [...data];
    updateData.splice(newIndex, 0, updateData.splice(oldIndex, 1)[0]);
    const newData = updateData.map((x, i) => ({ ...x, order_in_load: i + 1 }));
    onShipmentUpdate(newData);
  };

  const columns = useMemo(
    () =>
      currentUser
        ? [
            columnHelper.accessor('order_in_load', {
              header: 'Järjestys',
              size: 60,
              Cell: ({ table, cell, row }) => {
                const [value, setValue] = useState(cell.getValue());
                const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
                const open = Boolean(anchorEl);
                const handleClick = (event: React.MouseEvent<HTMLElement>) => {
                  setAnchorEl(event.currentTarget);
                };
                const handleClose = () => {
                  if (value) {
                    updateRowOrder(value - 1, row.index);
                  }
                  setAnchorEl(null);
                };

                return (
                  <>
                    <IconButton
                      data-cy="order-in-load-menu-button"
                      id="order-in-load-menu-button"
                      size="small"
                      aria-controls={open ? 'order-in-load-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? 'true' : undefined}
                      onClick={handleClick}
                    >
                      {cell.getValue()}
                    </IconButton>
                    <Menu
                      id="order-in-load-menu"
                      aria-labelledby="order-in-load-menu-button"
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                    >
                      <Input
                        data-cy="order-in-load-menu-input"
                        onFocus={(e) => e.target.select()}
                        sx={{ margin: '0.5rem' }}
                        type="number"
                        value={value}
                        onChange={(e) => setValue(parseInt(e.target.value))}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            handleClose();
                          }
                        }}
                      />
                    </Menu>

                    <IconButton
                      data-cy="order-in-load-decrease-button"
                      disabled={row.index === 0}
                      size="small"
                      onClick={() => {
                        table.setRowSelection({ [row.id]: true });
                        updateRowOrder(row.index - 1, row.index);
                      }}
                    >
                      <ArrowUpward fontSize="small" />
                    </IconButton>
                    <IconButton
                      data-cy="order-in-load-increase-button"
                      disabled={row.index === data.length - 1}
                      size="small"
                      onClick={() => {
                        table.setRowSelection({ [row.id]: true });
                        updateRowOrder(row.index + 1, row.index);
                      }}
                    >
                      <ArrowDownward fontSize="small" />
                    </IconButton>
                  </>
                );
              },
            }),
            columnHelper.accessor('id', {
              header: 'Id',
              Cell: ({ cell, renderedCellValue }) => (
                <Link component={RouterLink} to={{ pathname: `/shipments/${cell.getValue()}` }}>
                  {renderedCellValue}
                </Link>
              ),
              size: 60,
            }),
            columnHelper.accessor('reference_number', {
              header: 'Asiakasviite',
              size: 60,
            }),
            columnHelper.accessor((row) => `${row.pickup_address}, ${row.pickup_postal_code}, ${row.pickup_city}`, {
              id: 'pickupAddressFull',
              header: 'Nouto',
              Cell: ({ cell }) => (
                <AddressLink
                  data-cy="pickup-address-link"
                  title={cell.getValue<string>()}
                  address={cell.row.original.pickup_address}
                  postalCode={cell.row.original.pickup_postal_code}
                  city={cell.row.original.pickup_city}
                />
              ),
            }),
            columnHelper.accessor(
              (row) => `${row.delivery_address}, ${row.delivery_postal_code}, ${row.delivery_city}`,
              {
                id: 'deliveryAddressFull',
                header: 'Toimitus',
                Cell: ({ cell }) => (
                  <AddressLink
                    data-cy="delivery-address-link"
                    title={cell.getValue<string>()}
                    address={cell.row.original.delivery_address}
                    postalCode={cell.row.original.delivery_postal_code}
                    city={cell.row.original.delivery_city}
                  />
                ),
              },
            ),
            columnHelper.accessor('state', {
              header: 'Tila',
              sortingFn: (rowA, rowB) =>
                sortShipmentByStateAndTimestamp(rowA.original as Shipment, rowB.original as Shipment),
              Cell: ({ cell }) => {
                const stateUtils = getShipmentStateUtils(cell.row.original.state as ShipmentStateEnum);
                const label = getStateAndTimeStampFromShipment(cell.row.original as Shipment).formattedText ?? '';
                return (
                  <Chip
                    sx={{
                      background: stateUtils.backgroundColor,
                      color: stateUtils.color,
                      maxHeight: '1.5rem',
                    }}
                    icon={stateUtils.icon}
                    label={label ? label : cell.row.original.state}
                  />
                );
              },
            }),
            columnHelper.accessor(
              (row) =>
                row.agreed_delivery_window_starts_at && row.agreed_delivery_window_ends_at
                  ? `${formatTime(row.agreed_delivery_window_starts_at)}, ${formatTime(row.agreed_delivery_window_ends_at)}`
                  : '',
              {
                id: 'deliveryWindow',
                header: 'Toimitusaika',
                Cell: ({ row }) => (
                  <DeliveryWindow
                    startsAt={row.original.agreed_delivery_window_starts_at}
                    endsAt={row.original.agreed_delivery_window_ends_at}
                    onlyTime={true}
                  />
                ),
              },
            ),
            columnHelper.accessor('note', {
              header: 'Lisätietoja',
            }),
          ]
        : [],
    [currentUser, shipments],
  );

  const tableOptions: MRT_TableOptions<Shipment> = {
    data,
    columns,
    renderDetailPanel: ({ row }) => <ShipmentRowSubRowAsync row={row} />,
    enableRowOrdering: true,
    enableSorting: false,
    state: {
      pagination: { pageIndex: 0, pageSize: 100 },
    },
    muiSelectCheckboxProps: ({ row, table }) => ({
      autoFocus: row.getIsSelected(),
      onKeyDown: (event) => {
        const onlyOneRowIsSelected = Object.keys(table.getState().rowSelection).length === 1;
        const notFirstPlace = row.index !== 0;
        const notLastPlace = row.index !== data.length - 1;
        if (event.key === 'ArrowUp' && notFirstPlace && onlyOneRowIsSelected) {
          updateRowOrder(row.index - 1, row.index);
        }
        if (event.key === 'ArrowDown' && notLastPlace && onlyOneRowIsSelected) {
          updateRowOrder(row.index + 1, row.index);
        }
      },
    }),
    muiRowDragHandleProps: ({ table }) => ({
      onDragEnd: () => {
        const { draggingRow, hoveredRow } = table.getState();
        if (hoveredRow && hoveredRow.index !== undefined && draggingRow && draggingRow.index !== undefined) {
          updateRowOrder(hoveredRow.index, draggingRow.index);
        }
      },
    }),
  };

  const handleDelete = (shipments: Shipment[]) => onShipmentUpdate(shipments);

  return <MRTEditable header={'Toimitukset'} handleDelete={handleDelete} rowSelection={true} {...tableOptions} />;
};
