import { Dispatch, useEffect, useState } from 'react';
import { type TextFieldProps } from '@mui/material/TextField';
import { MRT_Cell, MRT_RowData, MRT_TableInstance } from 'material-react-table';
import { TimeClockView } from '../DateAndTimePickers/StandardTimePicker';
import React from 'react';
import { DateTime } from 'luxon';
import { timeFormat } from '../../formatters';
import { TimePicker, TimePickerProps } from '@mui/x-date-pickers';
import {
  Action,
  Field,
  FieldName,
  WorkHourWithOrganization,
  updateFieldValue,
} from '../../views/workHours/workHours.state';

interface MRT_MRT_EditCellDatePickerProps<TData extends MRT_RowData> extends TimePickerProps<DateTime> {
  cell: MRT_Cell<TData, any>;
  table: MRT_TableInstance<TData>;
  fields: {
    [P in FieldName]: Field<WorkHourWithOrganization[P] | ''>;
  };
  dispatch: Dispatch<Action>;
}

export const MRT_EditCellTimePicker = <TData extends Record<string, any>>({
  cell,
  table,
  fields,
  dispatch,
}: MRT_MRT_EditCellDatePickerProps<TData>) => {
  const {
    options: { muiEditTextFieldProps },
    refs: { editInputRefs },
  } = table as any;
  const { column, row } = cell;
  const { columnDef } = column;

  const mTableBodyCellEditTextFieldProps =
    muiEditTextFieldProps instanceof Function
      ? muiEditTextFieldProps({ cell, column, row, table })
      : muiEditTextFieldProps;

  const mcTableBodyCellEditTextFieldProps =
    (columnDef as any).muiEditTextFieldProps instanceof Function
      ? (columnDef as any).muiEditTextFieldProps({
          cell,
          column,
          row,
          table,
        })
      : (columnDef as any).muiEditTextFieldProps;

  const textFieldProps: TextFieldProps = {
    ...mTableBodyCellEditTextFieldProps,
    ...mcTableBodyCellEditTextFieldProps,
  } as any;

  const [value, setValue] = useState(
    cell.getValue<string>() ? DateTime.fromFormat(cell.getValue<string>(), timeFormat) : cell.getValue<string>(),
  );

  useEffect(() => {
    updateFieldValue(column.id as FieldName, cell.getValue<string>(), dispatch);
  }, []);

  const saveInputValueToRowCache = (newValue: string) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    row._valuesCache[column.id] = newValue;
  };

  const handleChange = (event: any) => {
    textFieldProps.onChange?.(event);
    setValue(event);
    const formattedValue = event ? event.toFormat(timeFormat) : event;
    updateFieldValue(column.id as FieldName, formattedValue, dispatch);
    saveInputValueToRowCache(formattedValue);
  };

  const { onError, slots, ...restOfProps } = textFieldProps;
  return (
    <TimePicker
      {...restOfProps}
      sx={{
        '& .MuiInputBase-input': {
          padding: 0,
        },
      }}
      key={column.id}
      format={timeFormat}
      value={value}
      viewRenderers={{ hours: TimeClockView, minutes: TimeClockView }}
      disabled={
        (columnDef.enableEditing instanceof Function ? columnDef.enableEditing(row) : columnDef.enableEditing) === false
      }
      inputRef={(inputRef) => {
        if (inputRef) {
          editInputRefs.current[column.id] = inputRef;
          if (textFieldProps.inputRef) {
            textFieldProps.inputRef = inputRef as any;
          }
        }
      }}
      slotProps={{
        textField: {
          name: column.id,
          required: (fields as any)[column.id].required,
          placeholder: '',
          helperText: (fields as any)[column.id].feedback ?? '',
          error: (fields as any)[column.id].hasError ?? false,
        },
      }}
      onChange={handleChange}
    />
  );
};
