import React, { FunctionComponent, useEffect, useReducer } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import Notification, { getSnackbarPropsFromState } from '../../components/Notification';
import { IconButton, Link, Tooltip } from '@mui/material';
import { EnrichedUser, initialState, load, reducer } from './users.state';
import { useCurrentUser } from '../../hooks/useCurrentUser';
import { createMRTColumnHelper, MRT_TableOptions } from 'material-react-table';
import MRT from '../../components/MaterialReactTable/MRT';
import { Add } from '@mui/icons-material';

const VIEW_ID = 'users' as const;

const Users: FunctionComponent = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const currentUser = useCurrentUser();
  const navigate = useNavigate();

  useEffect(() => {
    if (currentUser) {
      load(dispatch, currentUser);
    }
  }, [currentUser]);

  const data = React.useMemo(() => state.users, [state.users]);

  const columnHelper = createMRTColumnHelper<EnrichedUser>();

  const columns = React.useMemo(
    () => [
      columnHelper.accessor('username', {
        header: 'Käyttäjätunnus',
        Cell: ({ cell, renderedCellValue }) => (
          <Link component={RouterLink} to={{ pathname: `/users/${cell.getValue()}` }}>
            {renderedCellValue}
          </Link>
        ),
        filterFn: 'includesString',
      }),
      columnHelper.accessor('organizationName', {
        header: 'Asiakas',
        filterVariant: 'multi-select',
        filterFn: 'arrIncludesSome',
      }),
      columnHelper.accessor('first_name', {
        header: 'Etunimi',
      }),
      columnHelper.accessor('last_name', {
        header: 'Sukunimi',
      }),
    ],
    [],
  );

  const tableOptions: MRT_TableOptions<EnrichedUser> = {
    data: data,
    columns: columns,
    initialState: {
      sorting: [{ id: 'username', desc: false }],
    },
  };

  return (
    <>
      <MRT
        header={'Käyttäjät'}
        viewId={VIEW_ID}
        isLoading={state.isLoading}
        customActions={() => (
          <Tooltip title="Luo uusi käyttäjä">
            <span>
              <IconButton
                data-cy="create-new-user-button"
                disabled={state.isLoading}
                onClick={() => {
                  navigate('/users/new');
                }}
              >
                <Add color="success" />
              </IconButton>
            </span>
          </Tooltip>
        )}
        {...tableOptions}
      />
      <Notification {...getSnackbarPropsFromState(state, dispatch)} />
    </>
  );
};

export default Users;
