import { v4 as uuidv4 } from 'uuid';
import { ShipmentOrOrganizationShipment } from '../shipment.state';

const setAmountLimit = 20;
const shipmentSetsKey = 'shipment_sets';

type ShipmentSet = {
  id: string;
  created: Date;
  value: string[];
};
const isOldEnoughToRemove = (addDate: Date) => {
  const oneDayAgo = new Date();
  oneDayAgo.setDate(oneDayAgo.getDate() - 1);

  return addDate < oneDayAgo;
};

const getShipmentSets = (): ShipmentSet[] => JSON.parse(window.localStorage.getItem(shipmentSetsKey) ?? '[]');

const shipmentLink = (targetShipmentId: string, currentShipmentId: string, shipmentSetId: string) => {
  return {
    pathname: window.location.pathname.replace(currentShipmentId, targetShipmentId),
    search: `?shipmentSet=${shipmentSetId}`,
  };
};

export const previousAndNextShipmentLinks: () => [
  { pathname: string; search: string } | undefined,
  { pathname: string; search: string } | undefined,
] = () => {
  const params = new URLSearchParams(document.location.search);
  const shipmentSetId = params.get('shipmentSet');
  if (!shipmentSetId) {
    return [undefined, undefined];
  }
  const shipmentSet = getShipmentSets().find((set) => set.id == shipmentSetId);
  if (!shipmentSet) {
    return [undefined, undefined];
  }

  const currentShipmentId = window.location.pathname.split('/').pop();
  if (!currentShipmentId) {
    return [undefined, undefined];
  }
  const currentShipmentIndex = shipmentSet.value.indexOf(currentShipmentId);

  if (currentShipmentIndex == -1) {
    return [undefined, undefined];
  }

  const previousId = currentShipmentIndex == 0 ? undefined : shipmentSet.value[currentShipmentIndex - 1];
  const nextId =
    currentShipmentIndex >= shipmentSet.value.length - 1 ? undefined : shipmentSet.value[currentShipmentIndex + 1];
  const previous = previousId ? shipmentLink(previousId, currentShipmentId, shipmentSetId) : undefined;
  const next = nextId ? shipmentLink(nextId, currentShipmentId, shipmentSetId) : undefined;

  return [previous, next];
};

export const saveShipmentSet: (shipments: ShipmentOrOrganizationShipment[]) => string = (
  shipments: ShipmentOrOrganizationShipment[],
) => {
  if (shipments.length == 0) {
    return '';
  }
  const shipmentSets = getShipmentSets();

  const setId = uuidv4().toString();
  const ids = shipments.map((s) => s.id.toString());
  shipmentSets.push({ id: setId, created: new Date(), value: ids });

  let forceRemove = false;
  while (true) {
    if (forceRemove || (shipmentSets.length >= setAmountLimit && isOldEnoughToRemove(shipmentSets[0].created))) {
      // Remove first (oldest) element
      shipmentSets.splice(0, 1);
      forceRemove = false;
    } else {
      /*
      Try to save shipment sets, and if that fails, then force remove the oldest set.
      Failures are usually caused by too large value and this should fix that.
       */
      try {
        window.localStorage.setItem(shipmentSetsKey, JSON.stringify(shipmentSets));
        break;
      } catch (e) {
        // Something is really wrong, just return
        if (shipmentSets.length == 0) {
          console.error("Can't save shipment sets");
          return '';
        }
        forceRemove = true;
      }
    }
  }

  return setId;
};
