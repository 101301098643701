import { Dispatch } from 'react';
import { api, Car, CarService, getAllPages } from '../../api';
import { NotificationType, SetMessageAction } from '../../components/Notification';
import { SetLoadingAction } from '../../components/Loading';

type WithLastService = {
  lastService: Date | null;
};

export type CarWithLastService = Car & WithLastService;

export interface State {
  cars: CarWithLastService[];
  carServices: CarService[];
  notification: NotificationType;
  isLoading: boolean;
}

export const initialState: State = {
  cars: [],
  carServices: [],
  notification: {
    message: null,
  },
  isLoading: true,
};

export type Action =
  | { type: 'SET_CARS'; payload: CarWithLastService[] }
  | { type: 'SET_CAR_SERVICES'; payload: CarService[] }
  | SetMessageAction
  | SetLoadingAction;

export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'SET_CARS':
      return {
        ...state,
        cars: action.payload,
      };
    case 'SET_CAR_SERVICES':
      return {
        ...state,
        carServices: action.payload,
      };
    case 'SET_MESSAGE':
      return {
        ...state,
        notification: {
          message: action.payload.message,
          severity: action.payload.severity,
        },
      };
    case 'SET_LOADING':
      return {
        ...state,
        isLoading: action.payload,
      };
  }
};

export const load = async (dispatch: Dispatch<Action>): Promise<void> => {
  try {
    dispatch({
      type: 'SET_LOADING',
      payload: true,
    });
    const [carServicesResponse, carsResponse] = await Promise.all([
      api.carServices.getCarServices(),
      getAllPages(api.cars.getCars.bind(api.cars), { alsoInactive: true }),
    ]);
    dispatch({
      type: 'SET_CAR_SERVICES',
      payload: carServicesResponse.data,
    });
    dispatch({
      type: 'SET_CARS',
      payload: carsResponse.map((car) => {
        let lastServiceDate;
        const carServices = carServicesResponse.data.filter((carService) => carService.car_id === car.id);
        if (carServices.length > 0) {
          lastServiceDate = carServices.reduce((a, b) =>
            (a.service_date ?? 0) > (b.service_date ?? 0) ? a : b,
          ).service_date;
        }
        return {
          ...car,
          lastService: lastServiceDate ?? null,
        };
      }),
    });
  } catch (err) {
    console.error(err);
    dispatch({
      type: 'SET_MESSAGE',
      payload: {
        message: 'Virhe haettaessa autoja',
        severity: 'error',
      },
    });
  }
  dispatch({
    type: 'SET_LOADING',
    payload: false,
  });
};
